/* open-sans-300 - cyrillic_cyrillic-ext_vietnamese_latin-ext_greek-ext_latin_greek */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/open-sans-300.eot");
  /* IE9 Compat Modes */
  src: local("Open Sans Light"), local("OpenSans-Light"), url("../fonts/open-sans-300.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-300.woff2") format("woff2"), url("../fonts/open-sans-300.woff") format("woff"), url("../fonts/open-sans-300.ttf") format("truetype"), url("../fonts/open-sans-300.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

/* open-sans-300italic - cyrillic_cyrillic-ext_vietnamese_latin-ext_greek-ext_latin_greek */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: url("../fonts/open-sans-300italic.eot");
  /* IE9 Compat Modes */
  src: local("Open Sans Light Italic"), local("OpenSansLight-Italic"), url("../fonts/open-sans-300italic.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-300italic.woff2") format("woff2"), url("../fonts/open-sans-300italic.woff") format("woff"), url("../fonts/open-sans-300italic.ttf") format("truetype"), url("../fonts/open-sans-300italic.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

/* open-sans-regular - cyrillic_cyrillic-ext_vietnamese_latin-ext_greek-ext_latin_greek */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/open-sans-regular.eot");
  /* IE9 Compat Modes */
  src: local("Open Sans"), local("OpenSans"), url("../fonts/open-sans-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-regular.woff2") format("woff2"), url("../fonts/open-sans-regular.woff") format("woff"), url("../fonts/open-sans-regular.ttf") format("truetype"), url("../fonts/open-sans-regular.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

/* open-sans-italic - cyrillic_cyrillic-ext_vietnamese_latin-ext_greek-ext_latin_greek */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/open-sans-italic.eot");
  /* IE9 Compat Modes */
  src: local("Open Sans Italic"), local("OpenSans-Italic"), url("../fonts/open-sans-italic.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-italic.woff2") format("woff2"), url("../fonts/open-sans-italic.woff") format("woff"), url("../fonts/open-sans-italic.ttf") format("truetype"), url("../fonts/open-sans-italic.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

/* open-sans-600 - cyrillic_cyrillic-ext_vietnamese_latin-ext_greek-ext_latin_greek */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/open-sans-600.eot");
  /* IE9 Compat Modes */
  src: local("Open Sans Semibold"), local("OpenSans-Semibold"), url("../fonts/open-sans-600.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-600.woff2") format("woff2"), url("../fonts/open-sans-600.woff") format("woff"), url("../fonts/open-sans-600.ttf") format("truetype"), url("../fonts/open-sans-600.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

/* open-sans-600italic - cyrillic_cyrillic-ext_vietnamese_latin-ext_greek-ext_latin_greek */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  src: url("../fonts/open-sans-600italic.eot");
  /* IE9 Compat Modes */
  src: local("Open Sans Semibold Italic"), local("OpenSans-SemiboldItalic"), url("../fonts/open-sans-600italic.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-600italic.woff2") format("woff2"), url("../fonts/open-sans-600italic.woff") format("woff"), url("../fonts/open-sans-600italic.ttf") format("truetype"), url("../fonts/open-sans-600italic.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

/* open-sans-700 - cyrillic_cyrillic-ext_vietnamese_latin-ext_greek-ext_latin_greek */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/open-sans-700.eot");
  /* IE9 Compat Modes */
  src: local("Open Sans Bold"), local("OpenSans-Bold"), url("../fonts/open-sans-700.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-700.woff2") format("woff2"), url("../fonts/open-sans-700.woff") format("woff"), url("../fonts/open-sans-700.ttf") format("truetype"), url("../fonts/open-sans-700.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

/* open-sans-700italic - cyrillic_cyrillic-ext_vietnamese_latin-ext_greek-ext_latin_greek */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: url("../fonts/open-sans-700italic.eot");
  /* IE9 Compat Modes */
  src: local("Open Sans Bold Italic"), local("OpenSans-BoldItalic"), url("../fonts/open-sans-700italic.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-700italic.woff2") format("woff2"), url("../fonts/open-sans-700italic.woff") format("woff"), url("../fonts/open-sans-700italic.ttf") format("truetype"), url("../fonts/open-sans-700italic.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

/* open-sans-800 - cyrillic_cyrillic-ext_vietnamese_latin-ext_greek-ext_latin_greek */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/open-sans-800.eot");
  /* IE9 Compat Modes */
  src: local("Open Sans Extrabold"), local("OpenSans-Extrabold"), url("../fonts/open-sans-800.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-800.woff2") format("woff2"), url("../fonts/open-sans-800.woff") format("woff"), url("../fonts/open-sans-800.ttf") format("truetype"), url("../fonts/open-sans-800.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

/* open-sans-800italic - cyrillic_cyrillic-ext_vietnamese_latin-ext_greek-ext_latin_greek */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 800;
  src: url("../fonts/open-sans-800italic.eot");
  /* IE9 Compat Modes */
  src: local("Open Sans Extrabold Italic"), local("OpenSans-ExtraboldItalic"), url("../fonts/open-sans-800italic.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-800italic.woff2") format("woff2"), url("../fonts/open-sans-800italic.woff") format("woff"), url("../fonts/open-sans-800italic.ttf") format("truetype"), url("../fonts/open-sans-800italic.svg#OpenSans") format("svg");
  /* Legacy iOS */ }
